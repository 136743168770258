@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap%27');

.exp-img {
    background-color: "white";
    margin: auto;
    display: flex;
    padding: 5%;
}

.userProfile {
    color: #000;
    white-space: normal;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin: 4px;
    margin-right: 5px;
}

.exp-card-d {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    text-align: center !important;
    position: relative;
    transition: box-shadow .1s ease-in-out;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08);
    box-sizing: border-box;
    height: 450px;
    margin-bottom: 0;
}

.sam-wallace {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "metropolis", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-family: "metropolis", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: left;
    -webkit-box-direction: normal;
    visibility: visible;
    box-sizing: border-box;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    font-size: 2.5rem;
    margin-bottom: 1.6875rem;
    color: rgb(230, 230, 230)
}

.exp-card-title {
    -webkit-text-size-adjust: 100%;
    text-align: center !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1;
    color: #333;
    font-weight: bold;
}

.exp-sub-title {
    -webkit-text-size-adjust: 100%;
    text-align: center !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #333;
    font-weight: bold;
}

.exp-card-body1 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    text-align: center !important;
    color: #666;
}

.exp-card-body2 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-text-size-adjust: 100%;
    text-align: center !important;
    color: rgb(109, 109, 109);
    margin-top: 20px;
    margin-bottom: 0;
    font-style: italic;
}

.logo {
    -webkit-text-size-adjust: 100%;
    list-style: none;
    font-weight: 400;
    font-size: 1rem;
    font-family: Cantarell, sans-serif;
    cursor: pointer;
    color: #07a;
    text-decoration: none;
    position: relative;
    display: block;
    width: 7em;
    height: 7em;
    border-radius: 100%;
    border: solid 1px grey;
    line-height: 6.75em;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.post-info {
    color: #000;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

.blog-post-meta {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
    color: #666;
    text-align: center !important;
}

.blog-post-title {
    -webkit-text-size-adjust: 100%;
    --uk-breakpoint-s: 640px;
    --uk-breakpoint-m: 960px;
    --uk-breakpoint-l: 1200px;
    --uk-breakpoint-xl: 1600px;
    --uk-leader-fill-content: .;
    margin: 0 0 20px 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    color: #333;
    text-transform: none;
    line-height: 1.1;
    font-size: 3.5rem;
    text-align: center !important;
}

.blog-post-summary {
    color: #333;
}

.blog-post-body {
    font-family: 'Lato', sans-serif;
    color: #555;
    font-size: 1em;
    text-align: center;
}

.blog-post {
    padding: 20px;
}

.blog-post-publishedDate {
    text-align: center;
}

.blog-post-readingTime {
    text-align: center;
}

.blog-app-select {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    color: #666;
    --uk-breakpoint-s: 640px;
    --uk-breakpoint-m: 960px;
    --uk-breakpoint-l: 1200px;
    --uk-breakpoint-xl: 1600px;
    --uk-leader-fill-content: .;
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

h2 {
    color: rgb(204, 204, 204)
}

h3 {
    color: rgb(82, 82, 82);
    font-size: calc(0.9rem + .6vw);
}

p {
    color: #000
}

.nav-link {
    margin: auto;
}